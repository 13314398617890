<template>
  <v-card flat>
    <b> &nbsp;&nbsp; DHSV Test at {{ date }}</b>
    <v-row style="margin: 12px">
      <v-row dense class="rowh2">
        <v-col dense cols="12" sm="3" md="3">
          <v-text-field
            autocomplete="off"
            class="rowh0"
            type="number"
            outlined
            dense
            v-model="test_dhsv.hyd_oil"
            label="Hydraulic oil return vol"
            :readonly="true"
          >
          </v-text-field>
        </v-col>
        <v-col dense cols="12" sm="3" md="3">
          <v-text-field
            autocomplete="off"
            class="rowh0"
            type="number"
            outlined
            dense
            v-model="test_dhsv.o_time"
            label="Closing Time"
            :readonly="true"
          >
          </v-text-field>
        </v-col>
        <v-col dense cols="12" sm="3" md="3">
          <v-text-field
            autocomplete="off"
            class="rowh0"
            type="number"
            outlined
            dense
            v-model="test_dhsv.o_pressure"
            label="Opening Time"
            :readonly="true"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <br />
      &nbsp;&nbsp; Leak off test
      <v-row dense class="rowh2">
        <v-col dense cols="12" sm="3" md="3">
          <v-text-field
            autocomplete="off"
            class="rowh0"
            type="number"
            outlined
            dense
            v-model="test_dhsv.i_pressure"
            :label="'Initial pressure (' + pressure_unit + ')'"
            :readonly="true"
          >
          </v-text-field>
        </v-col>
        <v-col dense cols="12" sm="3" md="3">
          <v-text-field
            autocomplete="off"
            class="rowh0"
            type="number"
            outlined
            dense
            v-model="test_dhsv.f_pressure"
            :label="'Final pressure (' + pressure_unit + ')'"
            :readonly="true"
          >
          </v-text-field>
        </v-col>
        <v-col dense cols="12" sm="3" md="2">
          <v-text-field
            autocomplete="off"
            class="rowh0"
            type="number"
            outlined
            dense
            v-model="test_dhsv.time"
            label="time"
            :readonly="true"
          >
          </v-text-field>
        </v-col>
      </v-row>
    </v-row>
  </v-card>
</template>

<script>
export default {
  components: {},
  props: {
    test_dhsv: Object,
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {
    date() {
      if (this.test_dhsv) return this.test_dhsv.testdate;
      else return null;
    },
  },
  watch: {},
  methods: {},
  created() {
    this.pressure_unit = this.$store.state.options[0].pressure;
  },
};
</script>
<style>
.rowh2 {
  height: 40px !important;
  margin-bottom: 8px !important;
}
</style>
